
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureManagerService } from '@Services';
import { FeatureToggle, MrsColor } from 'app/enums';
import moment = require('moment');
import { SubSink } from 'subsink';


@Component({
    selector: 'follow-up-dialog',
    templateUrl: './follow-up-dialog.component.html',
    styleUrls: ['./follow-up-dialog.component.scss']
})
export default class FollowUpDialog implements OnInit, OnDestroy{

    followUpformGroup: FormGroup;
    buttonColor = MrsColor.UIBlue;
    title: string;
    isEdit = false;
    msg: string;
    followUp: string;
    readOnly = false;
    minDate: Date;
    maxDate: Date;
    clientId: string;

    subs = new SubSink();
    
    constructor(
        public dialogRef: MatDialogRef<FollowUpDialog>,
        protected featureManagerService: FeatureManagerService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder
    ) {
        this.title = data.title;
        this.msg = data.msg;
        this.followUp = data.followUp;
        this.clientId = data.clientId;
        this.followUpformGroup = _fb.group({ 'follow-up': [this.followUp] });

    }

    //#region Lifecycle

    ngOnInit() {
        this.subscribeToFeatureToggles();

    }

    //#endregion
    //#region Handlers

    subscribeToFeatureToggles() {
        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsFollowupDateAllowToday, this.clientId).subscribe(isEnabled => {
            if (isEnabled) {                
                this.minDate = moment().add(0, 'day').startOf('day').toDate();
                this.maxDate = moment().add(12, 'month').startOf('day').toDate();
            }else{                
                this.minDate = moment().add(1, 'day').startOf('day').toDate();
                this.maxDate = moment().add(12, 'month').startOf('day').toDate();
            }
        }));

    }

    updateFollowUp(): void {
        this.dialogRef.close({
            "followUp": this.followUpformGroup.get('follow-up').value
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    //#endregion

}
